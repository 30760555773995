import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import Announcement from "../components/Announcement/Announcement"
import Footer from "../components/Footer/footer"
import FooterLandingPage from "../components/Footer/footerLandingPage"
import FooterMinimal from "../components/Footer/footerMinimal"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StoreContext } from "../store"
import customerQuotes from "../utilities/customerQuotes"
import sectionBlocks from "../utilities/sectionBlocks"

const IndexPage = ({ data, location }) => {
  const setFooter = data.wpPage.headerAndFooter.pageFooterStyle

  const { content, uri } = data.wpPage

  const {
    viewPortWidth,
    isMobileMenuActive,
    isMobileSubActive,
    isAnnouncementActive,
  } = React.useContext(StoreContext)

  const [windowSize, setWindowSize] = React.useState(viewPortWidth)

  React.useEffect(() => {
    sectionBlocks()
    customerQuotes()
    setWindowSize(viewPortWidth)
  }, [viewPortWidth])

  return (
    <Layout>
      <Seo pageData={data.wpPage} location={location} />

      <div
        className={
          `home page-template-default page wp-embed-responsive has-imageRight header-default footer-default customize-support js ` +
          (windowSize < 841 && "Pacnav--is-mobile ") +
          (isMobileMenuActive && "Pacnav--is-active ") +
          (isMobileSubActive && "Pacnav--sub-is-active ")
        }
      >
        <div
          className={`Page ${isAnnouncementActive ? "has-announcement" : ""} `}
        >
          <Announcement />
          <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />

          <div className="Page-content">{parse(content)}</div>

          {setFooter === null && <Footer />}

          {setFooter === "default" && <Footer />}

          {setFooter === "landing-page" && <FooterLandingPage />}

          {setFooter === "get-started" && <FooterMinimal />}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    wpPage(isFrontPage: { eq: true }) {
      title
      content
      databaseId
      uri
      headerAndFooter {
        pageFooterStyle
        pageHeaderStyle
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
  }
`
